import React, {useEffect, useState} from "react";
import DraggableButton from "./DraggableButton";
import {PageType} from "../../utils/PageType";
import {useTranslation} from "react-i18next";
import {REGION_LOCK_ISO} from "../../utils/options";
import {redirect} from "react-router-dom";

interface WelcomeComponentProps {
    setPageType: (pageType: PageType) => void;
}

const WelcomeComponent : React.FC<WelcomeComponentProps> = ({ setPageType }) => {

    const { t } = useTranslation();
    const [isLoading, setLoading] = useState<boolean>(true);

    const fetchIPData = async () => {
        try {
            const response = await fetch('https://ipapi.co/json/', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            });

            if(!response.ok) {
                window.location.href = "/locked";
            }

            const jsonedResponse = await response.json();

            if(REGION_LOCK_ISO!.split(",").find(r => r == jsonedResponse.country_code) == undefined) {
                window.location.href = "/locked";
            } else {
                setLoading(false)
            }
        } catch (error) {
            window.location.href = "/locked";
        }
    };

    useEffect(() => {
        if(REGION_LOCK_ISO != undefined) {
            fetchIPData();
        } else {
            setLoading(false);
        }
    })

    if(isLoading) {
        return null;
    }

    return (
        <div className={"w-screen flex flex-col items-center"}>
            <div className={"flex flex-row items-center gap-8 mt-[10%]"}>
                <img src={"./assets/images/nusuk.png"} alt={"nusuk-logo"} className={"w-32"}/>
            </div>
            <div className="w-[82.5%] mt-[7.5%] flex flex-col items-center rounded-3xl"
                 style={{ backgroundImage: 'url(./assets/images/background-fill.png)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <img src={"./assets/images/gifts.png"} alt={"gifts"} className={"mt-[7.5%]"}/>
                <p className={"font-displace-medium text-white text-4xl"}>{t('freeSim')}</p>
                <div className={"flex flex-col items-center justify-center mt-[2%]"}>
                    <p className={"font-gilroy-semibold text-white text-sm"}>{t('freeSimFirstLine')}</p>
                    <p className={"font-gilroy-semibold text-white text-sm"}>{t('freeSimSecondLine')}</p>
                </div>
                <div className={"flex flex-col items-center justify-center mt-[10%] mb-[5%]"}>
                    <p className={"font-gilroy-semibold text-white text-xs"}>Powered by</p>
                    <img src={"./assets/images/logo.svg"} alt={"simly-logo"} className={"w-12 mt-[10%]"}/>
                </div>
            </div>
            <div className={"w-[70%] h-32 mt-[7.5%]"}>
                <div className={"w-100 h-16 bg-[#B49A75] relative rounded-2xl flex flex-row items-center justify-center"} onClick={() => setPageType(PageType.EMAIL)}>
                    <div className={"w-[70%] flex flex-row items-center justify-center"}>
                        <p className={"font-gilroy-semibold text-lg text-[#24282C]"}>{t('redeemNow')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WelcomeComponent;
