import {PageType} from "../../utils/PageType";
import React, {useState} from "react";
import {UserInfo} from "../../dto/UserInfo";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {CampaignService} from "../../services/CampaignService";

interface EmailComponentProps {
    setPageType: (pageType: PageType) => void;
    userInfo: UserInfo;
    setUserInfo: (userInfo: UserInfo) => void;
}

const EmailComponent : React.FC<EmailComponentProps> = ({ setPageType, userInfo, setUserInfo }) => {

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);

    const confirmEmail = async () => {
        try {
            setLoading(true)
            toast.dismiss();

            const isValidEmail = emailRegex.test(userInfo.email);

            if(!isValidEmail) {
                toast.error(t('emailIncorrect'), {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return;
            }

            const isEmailRedeemed = await CampaignService.isEmailRedeemed(userInfo.email);

            if(isEmailRedeemed) {
                toast.error("This email has already been redeemed!");
                return;
            }

            const resp = await CampaignService.getCampaignCountries();

            if(resp.local.length == 1 && resp.regional.length == 0 && resp.global.length == 0) {
                setPageType(PageType.REDEEMED);
                setUserInfo({
                    ...userInfo,
                    country: resp.local[0]
                })
            } else {
                setPageType(PageType.DESTINATION);
            }
        } catch (e) {
            toast.error((e as Error).message);
        } finally {
            setLoading(false)
        }

    }

    return (
        <div className={"w-screen h-screen flex flex-col items-center"}>
            <div className={"w-[90%] flex flex-col items-start mt-[7.5%]"}>
                <div className={"w-7 h-7 rounded-full bg-[#FAFAFC] flex items-center justify-center"} onClick={() => setPageType(PageType.WELCOME)}>
                    <img src={"./assets/images/back-arrow.svg"} alt={"back"}/>
                </div>
                <div className={"flex flex-col mt-[7.5%] w-full"}>
                    <p className={"font-gilroy-bold text-3xl text-black"}>{t('enterEmailHeader')}</p>
                    <p className={"font-gilroy-semibold text-lg text-[#BFBFB9] mt-[1%]"}>{t('enterEmailDescription')}</p>
                    <input
                        className={"mt-[7.5%] text-2xl text-black font-gotham-bold outline-none placeholder-[#BFBFB9] w-full"}
                        onChange={(e) => setUserInfo({
                            ...userInfo,
                            email: e.target.value!.trim()
                        })}
                        value={userInfo.email}
                        placeholder={t('enterEmail')}
                        type={"email"}
                        autoFocus
                    />
                </div>
                <div className={"mt-[15%] w-full flex flex-row justify-center items-center"}>
                    <div className={"flex flex-row items-center justify-center w-52 h-14 rounded-xl bg-[#B49A75] select-none"} onClick={() => confirmEmail()}>
                        {loading ?
                            <div
                                className="font-gotham-medium border-[#24282C] inline-block h-7 w-7 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-warning motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                role="status">
                                <span
                                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                >Loading...</span>
                            </div>
                            :
                            <p className="font-gilroy-semibold text-xl text-[#24282C]">
                                {t('nextButton')}
                            </p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailComponent;
